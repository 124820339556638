export const validateNumber = (rule, value, callback) => {
    var regex = /^[0-9]+$/;
    if (!regex.test(value)) {
        callback(
            new Error(
                "Please input 10 digit (only numerals 1-9)."
            )
        );
    } else {
        callback();
    }
}

export const RULES_RATE = {
    pre_employment_wealth_rate: [
        {
            required: true,
            message: "Rate Employment and wealth",
            trigger: "blur",
        },
    ],
    pre_good_talker_rate: [
        {
            required: true,
            message: "Rate good talker",
            trigger: "blur",
        },
    ],
    pre_family_social_status_rate: [
        {
            required: true,
            message: "Rate family and social status",
            trigger: "blur",
        },
    ],
    pre_education_rate: [
        {
            required: true,
            message: "Rate education",
            trigger: "blur",
        },
    ],

    pre_wiling_to_learn_rate: [
        {
            required: true,
            message: "Rate willing to learn",
            trigger: "blur",
        },
    ],
    pre_religiosity_or_faith_rate: [
        {
            required: true,
            message: "Rate religiosity and faith",
            trigger: "blur",
        },
    ],
    pre_manners_socialskill_ethics_rate: [
        {
            required: true,
            message: "Rate social skill and ethics",
            trigger: "blur",
        },
    ],
    pre_emotional_maturity_rate: [
        {
            required: true,
            message: "Rate emotional maturity",
            trigger: "blur",
        },
    ],
    pre_good_listener_rate: [
        {
            required: true,
            message: "Rate good listener",
            trigger: "blur",
        },
    ],
    pre_look_and_appearance_rate: [
        {
            required: true,
            message: "Rate look and appearance",
            trigger: "blur",
        },
    ],
    pre_strength_of_character_rate: [
        {
            required: true,
            message: "Rate strength character",
            trigger: "blur",
        },
    ],
}


export const RULES = {

    pre_employment_status: [
        {
            required: true,
            message: "Select your employment status",

        },
    ],
    pre_study_level_id: [
        {
            required: true,
            message: "Select your education status",

        },
    ],
    preferred_nationality: [
        {
            required: true,
            message: "Select a nationality",

        },
    ],
    pre_ethnicities: [
        {
            required: true,
            message: "Select a ethnicities",

        },
    ],
    prefReligions: [
        {
            required: true,
            message: "Select a religion",

        },
    ],
    preferred_nationality: [
        {
            required: true,
            message: "Select a nationality",

        },
    ],
    pre_occupation: [
        {
            required: true,
            message: "Select a occupation",

        },
    ],
    pre_partner_religion_id: [
        {
            required: true,
            message: "Select a religion",

        },
    ],
    // pre_other_preference: [
    //     {
    //         required: true,
    //         message: "Write your other preference",
    //         trigger: "change",
    //     },
    // ],
    // pre_description: [
    //     {
    //         required: true,
    //         message: "Write something",
    //         trigger: "change",
    //     },
    // ],
};

export const RULES_FAMILY_INFO = {
    father_profession: [{
        required: true,
        message: "Write your father's profession",
        trigger: "blur",
    }],
    mother_profession: [{
        required: true,
        message: "Write your mother's profession",
        trigger: "blur",
    }],
    siblings_desc: [{
        required: true,
        message: "Write about your siblings",
        trigger: "blur",
    }],
    family_info: [{
        required: true,
        message: "Write about your other family info",
        trigger: "blur",
    }],
    country_of_origin: [{
        required: true,
        message: "Select your country of origin",
        trigger: "blur",
    }],
}
export const VERIFICATION = {
    ver_city_id: [{
        required: true,
        message: "Select city",
        trigger: "blur",
    }],
    ver_country_id: [{
        required: true,
        message: "Select country",
        trigger: "blur",
    }],
    ver_document_type: [{
        required: true,
        message: "Select document type",
        trigger: "blur",
    }],
    // ver_recommences_address: [{
    //     required: true,
    //     message: "Write your address",
    //     trigger: "blur",
    // }],
    // ver_recommences_first_name: [{
    //     required: true,
    //     message: "Write your first name",
    //     trigger: "blur",
    // }],
    // ver_recommences_last_name: [{
    //     required: true,
    //     message: "Write your last name",
    //     trigger: "blur",
    // }],
    // ver_recommences_mobile_no: [{ validator: validateNumber, trigger: "change" },
    // { validator: validateNumber, trigger: "blur" },],
    // ver_recommences_occupation: [{
    //     required: true,
    //     message: "Select your occupation",
    //     trigger: "blur",
    // }],
    //     ver_recommences_title: [{
    //         required: true,
    //         message: "Write your title",
    //         trigger: "blur",
    //     }

    // ],
}
export const RULESPERSONALINFO = {
    per_smoker: [{
        required: true,
        message: "Select you are a smoker or not?",
        trigger: "blur",
    },],
    per_ethnicity: [{
        required: true,
        message: "Select your ethnicity",
        trigger: "blur",
    },],
    per_mother_tongue: [{
        required: true,
        message: "Select your mother tongue",
        trigger: "blur",
    },],
    per_height: [
        {
            required: true,
            message: "Select your height",
            trigger: "blur",
        }
    ],
    per_nationality: [{
        required: true,
        message: "Select your nationality",
        trigger: "blur",
    },],
    per_country_of_birth: [{
        required: true,
        message: "Select your country of birth",
        trigger: "blur",
    },],
    per_health_condition: [{
        required: true,
        message: "Write about your health and wellbeing",
        trigger: "blur",
    },],
    per_language_speak: [{
        required: true,
        message: "Select your language",
        trigger: "blur",
    },],
    per_hobbies_interests: [{
        required: true,
        message: "Select your hobbies and leisure interests",
        trigger: "blur",
    },],
    per_food_cuisine_like: [{
        required: true,
        message: "Select your favourite food and cuisine",
        trigger: "blur",
    },],
    per_things_enjoy: [{
        required: true,
        message: "Select your things that you enjoy",
        trigger: "blur",
    },],

    per_thankfull_for: [{
        required: true,
        message: "Select you thankful for?",
        trigger: "blur",
    },],
    per_about: [{
        required: true,
        message: "Write about you",
        trigger: "blur",
    },],
    mobile_number: [{
        required: true,
        message: "Mobile number is required",
        trigger: "blur",
    },
    { validator: validateNumber, trigger: "change" },
    { validator: validateNumber, trigger: "blur" },

    ],
    per_marital_status: [
        {
            required: true,
            message: "Select your marital status",
            trigger: "blur",
        }
    ],
    email: [
        {
            required: true,
            message: "Write your email address",
            trigger: "blur",
        }
    ],
    mobile_country_code: [{
        required: true,
        message: "Select your mobile country code",
        trigger: "blur",
    },],
    per_currently_living_with: [{
        required: true,
        message: "Select you are currently living arrangement?",
        trigger: "blur",
    },],
    per_willing_to_relocate: [{
        required: true,
        message: "Select you are willing to relocate",
        trigger: "blur",
    },],
    per_gender: [
        {
            required: true,
            message: "Select your gender",
            trigger: "blur",
        },
    ],
    dob: [
        {
            required: true,
            message: "Select your date of birth",
            trigger: "blur",
        },
    ],
    per_employment_status: [
        {
            required: true,
            message: "Select your employment status",
            trigger: "blur",
        },
    ],
    per_occupation: [
        {
            required: true,
            message: "Select your occupation",
            trigger: "blur",
        },
    ],
    per_education_level_id: [
        {
            required: true,
            message: "Select your education level",

        },
    ],
    per_religion_id: [
        {
            required: true,
            message: "Select your religion",
            trigger: "blur",
        },
    ],
    per_current_residence_country: [
        {
            required: true,
            message: "Select your currently resident country",
            trigger: "blur",
        },
    ],
    per_permanent_post_code: [
        {
            required: true,
            message: "Write your post code",
            trigger: "blur",
        },
    ],
    address_1: [
        {
            required: true,
            message: "Write address line one",
            trigger: "blur",
        },
    ],
    address_2: [
        {
            required: true,
            message: "Write address line two",
            trigger: "blur",
        },
    ],
    mobile_number: [
        {
            required: true,
            message: "Mobile number is required",
            trigger: "blur",
        },
    ],
    per_current_residence_city: [
        {
            required: true,
            message: "Select your currently resident city",
            trigger: "blur",
        },
    ],
   
    per_permanent_country: [
        {
            required: true,
            message: "Select your country",
            trigger: "blur",
        },
    ],
    postCode: [
        {
            required: true,
            message: "Select your postcode",
            trigger: "blur",
        },
    ],
    per_permanent_city: [
        {
            required: true,
            message: "Select your city",
            trigger: "blur",
        },
    ],

}
export const ARR = [
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
]

export const ARR_PersonalInfo = [
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    }


]

export const ARR_FAMILY_INFO = [
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    },
    {
        first: true,
    }
];

export const _PROFESSIONS = [
    "Academic Librarian",
    "Academic Researcher",
    "Accommodation Manager",
    "Account Collector",
    "Account Controller",
    "Account Executive",
    "Account Manager",
    "Account Representative",
    "Accountant",
    "Accounting Analyst",
    "Accounting Director",
    "Accounting Staff",
    "Accounting Technician",
    "Accounts Clerk",
    "Acoustic Consultant",
    "Actor",
    "Actuarial Analyst",
    "Actuary",
    "Acupuncturist",
    "Addiction Counsellor",
    "Administrative Analyst",
    "Administrative Assistant",
    "Administrative Manager",
    "Administrative Specialist",
    "Administrator",
    "Adult guidance Worker",
    "Adult Nurse",
    "Advertising Executive",
    "Advertising Planner",
    "Advertising Art Director",
    "Advertising Cpywriter",
    "Advice Worker",
    "Aerospace Engineer",
    "Agricultural Consultant",
    "Air Cabin Crew",
    "Air Traffic Controller",
    "Airline Pilot",
    "Amenity Horticulturist",
    "Anaesthetist",
    "Analytical Chemist",
    "Animal Breeder",
    "Animal Control Officer",
    "Animal Nutritionist",
    "Animal Physiotherapist",
    "Animal Shelter Board Member",
    "Animal Shelter Manager",
    "Animal Shelter Volunteer",
    "Animal Shelter Worker",
    "Animal Technician",
    "Animal Trainer",
    "Animator",
    "Application Analyst",
    "Application Developer",
    "Arbitrator",
    "Arboriculturist",
    "Archaeologist",
    "Architect",
    "Architectural Technologist",
    "Archivist",
    "Area Sales Manager",
    "Armed Forces Logistics Officer",
    "Armed Forces Operational Officer",
    "Armed Forces Technical Officer",
    "Art Gallery Manager",
    "Art therapist",
    "Art Worker",
    "Artificial Intelligence Engineer",
    "Artist",
    "Arts administrator",
    "Assistant Engineer",
    "Assistant Golf Professional",
    "Assistant Manager",
    "Assistant Professor",
    "Astronomer",
    "Atmospheric Scientist",
    "Attorney",
    "Auditing Clerk",
    "Auditor",
    "Automotive engineer",
    "B2B Sales Specialist",
    "Bailiff",
    "Bank Teller",
    "Barber",
    "Barista",
    "Barrister",
    "Barristers' clerk",
    "Beautician",
    "Bellhop",
    "Benefits Manager",
    "Biological Engineer",
    "Biologist",
    "Biomedical Engineer",
    "Biomedical Scientist",
    "Biostatistician",
    "Biotechnologist",
    "Board Member",
    "Board of Directors",
    "Boiler Engineer",
    "Boilermaker",
    "Bookkeeper",
    "Bookseller",
    "Border Force Officer",
    "Branch Manager",
    "Brand Manager",
    "Brand Strategist",
    "Bricklayer",
    "Broadcast engineer",
    "Broadcast journalist",
    "Broadcast presenter",
    "Budget Analyst",
    "Building control surveyor",
    "Building Inspector",
    "Building Services Engineer",
    "Building surveyor",
    "Bus Assistant",
    "Bus Driver",
    "Bus Person",
    "Business adviser",
    "Business analyst",
    "Business Development Manager",
    "Business Manager",
    "Cab Driver",
    "Cabinet Minister",
    "CAD technician",
    "Cafeteria Worker",
    "Call Centre Manager",
    "Call Centre Representative",
    "Camera Operator",
    "Cardiologist",
    "Care Manager",
    "Care Worker",
    "Careers Adviser",
    "Careers Information Officer",
    "Caregiver",
    "Carer",
    "Caretaker or House Sitter",
    "Carpenter",
    "Cartographer",
    "Cashier",
    "Casino Host",
    "Catering Manager",
    "CCO—Chief Customer Officer",
    "CCTV Supervisor",
    "CDO—Chief Data Officer",
    "Celebrity",
    "Chief Executive Officer",
    "Ceramics Designer",
    "Chief Financial Officer",
    "Chairman",
    "Chairperson",
    "Chaplain",
    "Charity Board Member",
    "Charity Fundraiser",
    "Charity Manager",
    "Charity officer",
    "Charity Volunteer",
    "Charity Worker",
    "Chartered Accountant",
    "Chartered legal executive",
    "Chartered loss adjuster",
    "Chef",
    "Chemical engineer",
    "Chemist",
    "Chemist",
    "Chief Engineer",
    "Chief Executive Officer",
    "Chief Information Officer",
    "Chief People Officer",
    "Chief Robot Whisperer",
    "Chief Technology Officer",
    "Child psychotherapist",
    "Children's nurse",
    "Chiropractor",
    "Choreographer",
    "Church Volunteer",
    "Civil Engineer",
    "Civil Service Administrator",
    "Civil Service Fast Streamer",
    "Client Service Specialist",
    "Clinical Psychologist",
    "Clinical Radiologist",
    "Clinical Research Associate",
    "Clinical scientist",
    "Clinical technologist",
    "Clothing technologist",
    "Cloud Architect",
    "Chief Marketing Officer",
    "Colour technologist",
    "Columnist",
    "Commercial & Residential surveyor",
    "Commercial Horticulturist",
    "Commercial Loan Officer",
    "Commissioning Editor",
    "Communications Director",
    "Communications Engineer",
    "Community Development worker",
    "Community Education Officer",
    "Community Food Project Worker",
    "Community Pharmacist",
    "Community Volunteer Jobs",
    "Company secretary",
    "Compliance officer",
    "Computer Animator",
    "Computer Programmer",
    "Computer Scientist",
    "Concept artist",
    "Concierge",
    "Concierge Assistant",
    "Concierge Manager",
    "Conference Centre Manager",
    "Conservation Scientist",
    "Conservation Volunteer",
    "Conservation Volunteer",
    "Conservator",
    "Construction Manager",
    "Construction Worker",
    "Consulting civil Engineer",
    "Content Creator",
    "Content Marketing Manager",
    "Content Strategist",
    "Continuous Improvement Lead",
    "Control and instrumentation engineer",
    "Controller",
    "Coordinator",
    "Copy Editor",
    "Copywriter",
    "Corporate Investment Banker",
    "Corporate Treasurer",
    "Cosmetologist",
    "Costs Lawyer",
    "Councillor",
    "Counselling Psychologist",
    "Counsellor",
    "Couples Counsellor",
    "Courier",
    "Court Clerk",
    "Court Official",
    "Chief Product Officer",
    "Crane Operator",
    "Creative director",
    "Credit Advisor",
    "Credit Authorizer",
    "Credit Counsellor",
    "Crime Scene Investigator",
    "Cruise Director",
    "Cruise Ship Attendant",
    "CTO—Chief Technology Officer",
    "Culture Operations Manager",
    "Customer Care Associate",
    "Customer Service",
    "Customer Service Assistant",
    "Customer Service Manager",
    "Customer Service Officer",
    "Customer Support Officer",
    "Customer Support Representative",
    "Cyber Security Analyst",
    "Dance Movement psychotherapist",
    "Dancer",
    "Data Analyst",
    "Data Analyst",
    "Data Entry Assistant",
    "Data Entry Clerk",
    "Data scientist",
    "Database Administrator",
    "Debt Collector",
    "Delivery Driver",
    "Dental Hygienist",
    "Dental technician",
    "Dental therapist",
    "Dentist",
    "Deputy Headteacher",
    "Deputy Mayor",
    "Design engineer",
    "Designer",
    "Detective",
    "DevOps Engineer",
    "Diagnostic Radiographer",
    "Dietitian",
    "Digital copywriter",
    "Digital marketer",
    "Digital Marketing Manager",
    "Digital Overlord",
    "Digital Product Manager",
    "Diplomatic service officer",
    "Direct Salesperson",
    "Director",
    "Director of Bean Counting",
    "Director of Ethical Hacking",
    "Director of First Impressions",
    "Director of Inside Sales",
    "Director of Maintenance",
    "Director of Photography",
    "Director of Storytelling",
    "Dispatcher for Trucks or Taxis",
    "Dispensing optician",
    "Doctor",
    "Dog Trainer",
    "Dog Walker / Pet Sitter",
    "Drafter",
    "Dramatherapist",
    "Drilling engineer",
    "Early years teacher",
    "Ecologist",
    "eCommerce Marketing Specialist",
    "Economist",
    "Editor",
    "Editorial Assistant",
    "Education administrator",
    "Education consultant",
    "Educational psychologist",
    "Electrical engineer",
    "Electrician",
    "Electronic Engineer",
    "Emergency planning officer",
    "Emergency Relief Worker",
    "Energy engineer",
    "Energy manager",
    "Engineer",
    "Engineering geologist",
    "Engineering Technician",
    "ESOL Teacher",
    "Entertainment Specialist",
    "Entrepreneur",
    "Environmental consultant",
    "Environmental education officer",
    "Environmental engineer",
    "Environmental health practitioner",
    "Environmental manager",
    "Epidemiologist",
    "Equality and inclusion officer",
    "Ergonomist",
    "Estate agent",
    "Estates manager",
    "Esthetician",
    "Estimator",
    "Event manager",
    "Event Planner",
    "Events Manager",
    "Executive",
    "Executive Assistant",
    "Exercise physiologist",
    "Exhibition designer",
    "External auditor",
    "Facilities manager",
    "Family support worker",
    "Farm manager",
    "Farm Worker",
    "Fashion designer",
    "Fast Food Worker",
    "Field trials officer",
    "File Clerk",
    "Film Critic",
    "Film director",
    "Film/video editor",
    "Finance Director",
    "Finance Manager",
    "Financial adviser",
    "Financial Analyst",
    "Financial Analyst",
    "Financial manager",
    "Financial Planner",
    "Financial risk analyst",
    "Financial trader",
    "Fine artist",
    "Fire risk assessor",
    "Firefighter",
    "Fish farm manager",
    "Fisheries officer",
    "Fitness centre manager",
    "Flight Attendant",
    "Food Kitchen Worker",
    "Food technologist",
    "Foreman",
    "Forensic accountant",
    "Forensic computer analyst",
    "Forensic psychologist",
    "Forensic scientist",
    "Forest/woodland manager",
    "Founder",
    "Freight forwarder",
    "Front Desk Associate",
    "Front Desk Manager",
    "Front-Line Employee",
    "Funeral Attendant",
    "Funeral Director",
    "Furniture conservator",
    "Furniture designer",
    "Further Education Teacher",
    "Game artist",
    "Game designer",
    "Game developer",
    "General practice doctor",
    "Genetic counsellor",
    "Geochemist",
    "Geological Engineer",
    "Geologist",
    "Geophysicist",
    "Geoscientist",
    "Geotechnical engineer",
    "Ghostwriter",
    "Glass designer",
    "Governor",
    "Grant Writer",
    "Graphic designer",
    "Group Sales",
    "Guidance Counselor",
    "Habitat for Humanity Builder",
    "Hair Stylist",
    "Handyman",
    "Head",
    "Headteacher",
    "Health and safety adviser",
    "Health and safety inspector",
    "Health play specialist",
    "Health promotion specialist",
    "Health psychologist",
    "Health service manager",
    "Health visitor",
    "Health Worker",
    "Heavy Equipment Operator",
    "Hedge Fund Manager",
    "Help Desk Assistant",
    "Help Desk Officer",
    "Help Desk Technician",
    "Help Desk Worker",
    "Herbalist",
    "Heritage manager",
    "High intensity therapist",

    "Higher education lecturer",
    "Holiday representative",
    "Homeless Shelter Worker",
    "Homeopath",
    "Horticultural consultant",
    "Horticultural therapist",
    "Hospital doctor",
    "Hospital pharmacist",
    "Hospital Volunteer",
    "Hotel Front Door Greeter",
    "Hotel manager",
    "Hotel Receptionist",
    "Housekeeper",
    "Housing manager",
    "Human Resources Assistant",
    "Human Resources Manager",
    "Human Resources Officer",
    "Human Resources Specialist",
    "HVAC Technician",
    "Hydrogeologist",
    "Hydrographic surveyor",
    "Hydrologist",
    "Illustrator",
    "Imam",
    "Influencer",
    "Information officer",
    "Information Security Analyst",
    "Information systems manager",
    "Insurance account manager",
    "Insurance broker",
    "Insurance claims handler",
    "Insurance risk surveyor",
    "Insurance underwriter",
    "Intelligence analyst",
    "Interior and spatial designer",
    "Interior Designer",
    "Internal auditor",
    "International development worker",
    "Interpreter",
    "Investment Analyst",
    "Iron Worker",
    "IT consultant",
    "IT Manager",
    "IT Professional",
    "IT sales professional",
    "IT Security Expert",
    "IT technical support officer",
    "IT trainer",
    "IT Worker ",
    "Jewellery designer",
    "Journalist",
    "Judge",
    "Khatib",
    "Land/geomatics surveyor",
    "Land-based engineer",
    "Landscape architect",
    "Landscaping Assistant",
    "Landscaping Worker",
    "Leader",
    "Learning disability nurse",
    "Learning mentor",
    "Lecturer, College",
    "Lecturer, University",
    "Lexicographer",
    "Librarian",
    "Librarian",
    "Library Assistant",
    "Licensed conveyancer",
    "Life coach",
    "Lighting technician",
    "Line Cook",
    "List of jobs",
    "Local Government Councillor",
    "Local government officer",
    "Location manager",
    "Locksmith",
    "Lodging Manager",
    "Logistics and distribution manager",
    "Machine learning engineer",
    "Machinery Operator",
    "Madrasah Teacher",
    "Magazine features editor",
    "Magazine journalist",
    "Magistrate",
    "Magistrate",
    "Mail Carrier",
    "Maintenance Engineer",
    "Makeup Artist",
    "Management Consultant",
    "Management Consultant",
    "Manager",
    "Managing Member",
    "Managing Partner",
    "Manicurist",
    "Manufacturing Assembler",
    "Manufacturing engineer",
    "Manufacturing systems engineer",
    "Marine biologist",
    "Marine scientist",
    "Market Development Manager",
    "Market Researcher",
    "Marketing Communications Manager",
    "Marketing Consultant",
    "Marketing Coordinator",
    "Marketing Director",
    "Marketing executive",
    "Marketing Manager",
    "Marketing Research Analyst",
    "Marketing Specialist",
    "Marketing Staff",
    "Masajid Volunteer",
    "Mason",
    "Massage Therapy",
    "Materials engineer",
    "Mathematician",
    "Maulana (Aalim)",
    "Mayor",
    "Meals on Wheels Driver",
    "Mechanic",
    "Mechanical engineer",
    "Media buyer",
    "Media planner",
    "Media Relations Coordinator",
    "Media researcher",
    "Mediator",
    "Medical Administrator",
    "Medical Assistant",
    "Medical Consultant",
    "Medical Doctor",
    "Medical Doctor, Clinic",
    "Medical Doctor, Hospital",
    "Medical illustrator",
    "Medical Laboratory Tech",
    "Medical Laboratory Tech",
    "Medical Researcher",
    "Medical sales representative",
    "Medical science liaison",
    "Medical Transcriptionist",
    "Medicinal chemist",
    "Meeting Planner",
    "Mental Health Counselor",
    "Mental health nurse",
    "Mentor",
    "Merchandising Associate",
    "Merchant Navy officer",
    "Metallurgist",
    "Meteorologist",
    "Microbiologist",
    "Midwife",
    "Minerals surveyor",
    "Mining Engineer",
    "Minister",
    "Molecular Scientist",
    "Mortgage adviser",
    "Mortgage Loan Processor",
    "Mosque Volunteer",
    "Motion Picture Director",
    "Mover",
    "Mudlogger",
    "Multimedia programmer",
    "Multimedia specialist",
    "Museum & gallery curator",
    "Museum & gallery officer",
    "Museum education officer",
    "Music Producer",
    "Music therapist",
    "Musician",
    "Nail Technician",
    "Nanotechnologist",
    "Nature conservation officer",
    "Naturopath",
    "Naval architect",
    "Network Administrator",
    "Network engineer",
    "Neurologist",
    "Neuroscientist",
    "Newspaper journalist",
    "NGO Fundraiser",
    "NGO Manager",
    "NGO Worker",
    "Novelist/Writer",
    "Nuclear engineer",
    "Nuclear Engineer",
    "Nurse",
    "Nurse",
    "Nurse Practitioner",
    "Nursing Assistant",
    "Nutritional therapist",
    "Nutritionist",
    "Occupational hygienist",
    "Occupational psychologist",
    "Occupational therapist",
    "Oceanographer",
    "Office Assistant",
    "Office Clerk",
    "Office manager",
    "Office Volunteer",
    "Officer",
    "Online ESL Instructor",
    "Operational researcher",
    "Operations Analyst",
    "Operations Assistant",
    "Operations Coordinator",
    "Operations Director",
    "Operations Manager",
    "Operations Professional",
    "Ophthalmologist",
    "Optician",
    "Options Analyst",
    "Optometrist",
    "Orderly",
    "Organizer",
    "Orthoptist",
    "Osteopath",
    "Other",
    "Outdoor activities manager",
    "Outside Sales Manager",
    "Over the Phone Interpreter",
    "Overseer",
    "Owner",
    "Painter",
    "Paralegal",
    "Paramedic",
    "Parking Attendant",
    "Patent attorney",
    "Patent examiner",
    "Pathologist",
    "Pathology technologist",
    "Payroll Clerk",
    "Payroll Manager",
    "Penetration tester",
    "Pension scheme manager",
    "Pensions consultant",
    "Pensions Manager",
    "Personal Assistant",
    "Personal Trainer",
    "Petroleum engineer",
    "Pharmacist",
    "Pharmacologist",
    "Pharmacy Assistant",
    "Phlebotomist",
    "Phone Sales Specialist",
    "Phone Survey Conductor",
    "Photographer",
    "Physical Therapist",
    "Physical Therapy Assistant",
    "Physician associate",
    "Physicist",
    "Physiotherapist",
    "Physiotherapist",
    "Pipefitter",
    "Plant breeder/geneticist",
    "Plant Engineer",
    "Plasterer",
    "Play therapist",
    "Playwright",
    "Plumber",
    "Podiatrist",
    "Police officer",
    "Political risk analyst",
    "Political Scientist",
    "Politician",
    "Politician's assistant",
    "Porter",
    "PPC specialist",
    "Preschool Teacher",
    "Presenter",
    "President",
    "President of Sales",
    "Press photographer",
    "Press sub-editor",
    "Primary school teacher",
    "Principal",
    "Print production planner",
    "Printmaker",
    "Prison officer",
    "Private music teacher",
    "Private tutor",
    "Probation officer",
    "Process development scientist",
    "Product designer",
    "Product manager",
    "Production designer",
    "Production Engineer",
    "Production manager",
    "Professor",
    "Program Administrator",
    "Program Manager",
    "Proofreader",
    "Property Investor",
    "Proposal Writer",
    "Proprietor",
    "Prosthetist & orthoptist",
    "Psychiatrist",
    "Psychotherapist",
    "Public affairs consultant",
    "Public house manager",
    "Public librarian",
    "Public Relations",
    "Public relations officer",
    "Public Relations Specialist",
    "Publishing copy-editor",
    "Publishing rights manager",
    "Purchasing manager",
    "Purchasing Staff",
    "Quality Control Coordinator",
    "Quality Controller",
    "Quality Engineer",
    "Quantity surveyor",
    "Quarry manager",
    "Raakhi (religious)",
    "Radio broadcast assistant",
    "Radio producer",
    "Real Estate Assistant",
    "Real Estate Broker",
    "Real Estate Investor",
    "Real Estate Manager",
    "Receptionist",
    "Records manager",
    "Recruiter",
    "Recruitment consultant",
    "Recyclables Collector",
    "Recycling officer",
    "Red Cross Volunteer",
    "Regulatory affairs officer",
    "Reiki Practitioner",
    "Religious Teacher",
    "Remote Online Service Specialist",
    "Research Assistant",
    "Research scientist",
    "Researcher",
    "Reservationist",
    "Restaurant Chain Executive",
    "Restaurant Manager",
    "Retail banker",
    "Retail buyer",
    "Retail manager",
    "Retail merchandiser",
    "Retail Worker",
    "Risk manager",
    "Roofer",
    "Rural practice surveyor",
    "Safety Engineer",
    "Sales Analyst",
    "Sales Associate",
    "Sales Engineer",
    "Sales executive",
    "Sales Manager",
    "Sales Representative",
    "Salon Manager",
    "School Bus Driver",
    "School Counsellor",
    "School Volunteer",
    "Science writer",
    "Screenwriter",
    "Scrum Master",
    "Secondary school teacher",
    "Secretary",
    "Security Guard",
    "Senior tax professional",
    "Senior Teacher",
    "Senior Teacher and Leader",
    "SEO Manager",
    "SEO specialist",
    "Server",
    "Service Dog Trainer",
    "Shareholder",
    "Sheet Metal Worker",
    "Shelf Stocker",
    "Shipping and Receiving Staff",
    "Shop Assistant",
    "Site engineer",
    "Skin Care Specialist",
    "Social Media Assistant",
    "Social Media Influencer",
    "Social media manager",
    "Social Media Specialist",
    "Social researcher",
    "Social worker",
    "Sociologist",
    "Software engineer",
    "Software Ninjaneer",
    "Software tester",
    "Soil scientist",
    "Solar Photovoltaic Installer",
    "Solicitor",
    "Sound engineer",
    "Sound technician",
    "Spa Manager",
    "Special educational needs teacher",
    "Special effects technician",
    "Speech and language therapist",
    "Speech Pathologist",
    "Speechwriter",
    "Sport and exercise psychologist",
    "Sports administrator",
    "Sports coach",
    "Sports development officer",
    "Sports therapist",
    "Sports Volunteer",
    "SQL Developer",
    "Statistician",
    "Stockbroker",
    "Store Manager",
    "Structural engineer",
    "Stylist",
    "Substitute Teacher",
    "Suicide Hotline Volunteer",
    "Superintendent",
    "Supervisor",
    "Supply chain manager",
    "Surgeon",
    "Sustainability consultant",
    "Synagogue Volunteer",
    "Systems analyst",
    "Talent agent",
    "Taper",
    "Tax adviser",
    "Teacher",
    "Teacher, High School",
    "Teacher, Primary School",
    "Teacher, Secondary School",
    "Teaching assistant",
    "Teaching laboratory technician",
    "Team Leader",
    "Technical author",
    "Technical brewer",
    "Technical sales engineer",
    "Technical Specialist",
    "Technical Support Specialist",
    "Technical Writer",
    "Telecommunications researcher",
    "Telemarketer",
    "Telephone Operator",
    "Television camera operator",
    "Television floor manager",
    "Television production coordinator",
    "Television, film, video producer",
    "Telework Nurse/Doctor",
    "Temple Volunteer",
    "Test Scorer",
    "Textile designer",
    "Theatre director",
    "Theatre manager",
    "Theatre stage manager",
    "Theatre, television, film worker",
    "Theme park manager",
    "Therapeutic radiographer",
    "Therapist",
    "Third Sector Manager",
    "Title Analyst",
    "Title Researcher",
    "Tour manager",
    "Tourism officer",
    "Tourist Centre Manager",
    "Tow Truck Operator",
    "Town Planner",
    "Toxicologist",
    "Trade Mark Attorney",
    "Trade Union Official",
    "Trade Union Worker",
    "Trader",
    "Trading Standards Officer",
    "Training and Development Officer",
    "Translator",
    "Transport Manager",
    "Transport Planner",
    "Travel Agency Manager",
    "Travel Agent",
    "Travel Nurse",
    "Travel Writer",
    "Treasurer",
    "Truck Driver",
    "Tutor/Online Tutor",
    "Uber Driver",
    "UI Specialist",
    "UPS Driver",
    "Urban designer",
    "UX designer",
    "UX Designer & UI Developer",
    "UX researcher",
    "UX Specialist",
    "Valet",
    "Vehicle or Equipment Cleaner",
    "Veterinary Assistant",
    "Veterinary nurse",
    "Veterinary surgeon",
    "VFX artist",
    "Vice President of Marketing",
    "Vice President of Operations",
    "Vice-president",
    "Video Editor",
    "Video Game Writer",
    "Video or Film Producer",
    "Virtual Assistant",
    "Visual Merchandiser",
    "Voluntary Sector Manager",
    "Volunteer Coordinator",
    "VP of Miscellaneous Stuff",
    "Wait Staff Manager",
    "Waiter/Waitress",
    "Warehouse manager",
    "Warehouse Worker",
    "Waste management officer",
    "Water engineer",
    "Water quality scientist",
    "Web content manager",
    "Web Designer",
    "Web Developer",
    "Wedding Coordinator",
    "Welder",
    "Welding Specialist",
    "Well Driller",
    "Wellsite geologist",
    "Women’s Shelter Worker ",
    "Writer",
    "Yoga Instructor",
    "Youth Volunteer",
    "Youth worker",
    "Youtuber",
    "Zookeeper",
    "Zoologist",
];

export const validateMobile = (rule, value, callback) => {
    if (!value) {
        callback(new Error("Enter your valid mobile number"));
    }

    else {
        callback();
    }
};